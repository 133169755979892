import {ComponentStore} from '@ngrx/component-store';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Localstorage} from 'src/app/domain/service/storage/localstorage';

export interface CustomerOperativeBlockedState {
  personalDataCompleted: boolean;
  kycUpdated: boolean;
  vatExpired: boolean;
  date: Date;
}

export const OPERATIVE_BLOCKED_NAMESPACE = '__operative_blocked__';

const emptyState = () => ({
  personalDataCompleted: false,
  kycUpdated: false,
  vatExpired: false,
  date: new Date()
});

const hourMilliseconds = 1000 * 60 * 60;
const hoursToOutdated = 24;

@Injectable({
  providedIn: 'root'
})
export default class CustomerOperativeBlockedStepsStore extends ComponentStore<CustomerOperativeBlockedState> {
  public readonly customerOperativeBlockedSteps$ = this.select(state => state);

  constructor(private localStorage: Localstorage) {
    super(emptyState());
  }

  public saveAll(customerOperativeBlockedSteps: CustomerOperativeBlockedState): void {
    this.patchState(() => customerOperativeBlockedSteps);

    if (this.isOutdated()) {
      this.reset();
      return;
    }

    this.localStorage.syncSet(OPERATIVE_BLOCKED_NAMESPACE, JSON.stringify(customerOperativeBlockedSteps));
  }

  public getSync(): CustomerOperativeBlockedState {
    if (this.isOutdated()) {
      this.reset();
    }
    return this.get();
  }

  public getCustomerOperativeBlockedSteps(): Observable<CustomerOperativeBlockedState> {
    return this.customerOperativeBlockedSteps$;
  }

  public isOutdated(): boolean {
    return (new Date().getTime() - this.get().date.getTime()) / hourMilliseconds > hoursToOutdated;
  }

  public reset(): void {
    this.patchState(() => ({
      ...emptyState()
    }));

    this.localStorage.unset(OPERATIVE_BLOCKED_NAMESPACE);
  }
}
