<div class="w-full"
     [ngClass]="{
        'bg-primary-background': theme === 'primary',
        'bg-white': theme === 'light',
        'bg-green-600': theme === 'success',
        'bg-error': theme === 'error',
        'bg-black': theme === 'premium'
     }"
>
  <div class="px-4 py-3 font-bold cursor-pointer grid grid-cols-10 left-4 sm:py-5 place-items-center"
       [ngClass]="{
          'text-white': (theme === 'primary' || theme === 'success' || theme === 'error'),
          'text-premium': theme === 'premium'
       }"
  >
    <div class="text-left col-span-1">
      <ng-content select="[leftLinks]"></ng-content>
    </div>
    <div class="flex flex-col items-center text-center uppercase col-span-8">
      <ion-icon *ngIf="icon !== undefined" [name]="icon" class="py-2 text-5xl"></ion-icon>
      <span *ngIf="title !== undefined">{{title}}</span>
    </div>
    <div class="text-right cursor-pointer col-span-1">
      <ng-content select="[rightLinks]"></ng-content>
    </div>
  </div>
</div>
<div class="text-center py-2 mobile:py-5 empty:hidden"
     [ngClass]="{
        'bg-primary-background': theme === 'primary',
        'text-white': (theme === 'primary' || theme === 'success' || theme === 'error'),
        'bg-green-600': theme === 'success',
        'bg-error': theme === 'error',
        'bg-white': theme === 'light'
     }"
>
  <ng-content select="[description]"></ng-content>
</div>
